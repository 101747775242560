import { Component, OnInit } from '@angular/core';
import { RestCallService } from '../rest-call.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../auth.service';



export class Track {
  constructor(public title:string, public link:string){}
}

@Component({
  selector: 'app-songs',
  templateUrl: './songs.component.html',
  styleUrls: ['./songs.component.scss']
})
export class SongsComponent implements OnInit {
  msaapPlaylist: Track[] = [];
  rest= new RestCallService(this.http, this.cookieService);
  auth = new AuthService(this.cookieService)


  constructor(private http: HttpClient, private cookieService: CookieService) { }

    ngOnInit() {
      this.rest.getSongs().subscribe((res : any[])=>{
        for (let index = 0; index < res["songs"].length; index++) {
          let title = res["songs"][index];
          let newTrack = new Track(title.replace(".mp3",""),"/assets/songs/"+title);
          this.msaapPlaylist.push(newTrack);
        }
    });

    if (!this.auth.isAdmin()){
      this.rest.addClick().subscribe((res : any[])=> {
        console.log(res)
      });
    }
    }

  

}
