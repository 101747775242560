import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Md5 } from 'ts-md5/dist/md5';

import { MainComponent } from './main/main.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { VideosComponent } from './videos/videos.component';
import { PhotosComponent } from './photos/photos.component';
import { SongsComponent } from './songs/songs.component';
import { DatesComponent } from './dates/dates.component';
import { WhoIsAbramComponent } from './who-is-abram/who-is-abram.component';
import { AdminComponent } from './admin/admin.component';


@NgModule({
  declarations: [
    MainComponent,
    HomeComponent,
    ContactComponent,
    VideosComponent,
    PhotosComponent,
    SongsComponent,
    DatesComponent,
    WhoIsAbramComponent,
    AdminComponent
    ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    BrowserAnimationsModule,
  ],
  providers: [CookieService],
  bootstrap: [MainComponent],
  entryComponents: []
})
export class AppModule { }
