import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../auth.service';
import { RestCallService } from '../rest-call.service';



@Component({
  selector: 'app-root',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

 isCollapsed=true;
 auth = new AuthService(this.cookieService);
 rest= new RestCallService(this.http, this.cookieService);


  constructor(private http: HttpClient, private cookieService: CookieService) {}

  ngOnInit(){
    if (!this.auth.isAdmin()){
      this.rest.addVisit().subscribe((res : any[])=> {
        console.log(res)
      });
    }
  }

}
