import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { CookieService } from 'ngx-cookie-service';
import { RestCallService } from '../rest-call.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(private http: HttpClient,private cookieService: CookieService) { }
  auth = new AuthService(this.cookieService);
  rest= new RestCallService(this.http, this.cookieService);
  selected_pass: string;
  ngOnInit() {
    if (this.auth.isAdmin()){
      this.rest.getStats().subscribe((res)=>{
        this.visits = res["visits"];
        this.clicks = res["clicks"];
        this.yesterdayClicks = res["yesterdayClicks"];
        this.yesterdayVisits = res["yesterdayVisits"];

      });
    }
    else {
    }

  }

  visits = 0;
  clicks = 0;
  yesterdayVisits = 0;
  yesterdayClicks = 0;
  login(){
    if (this.auth.setAdmin((<HTMLInputElement>document.getElementById("password-field")).value)){
      this.rest.getStats().subscribe((res)=>{
        this.visits = res["visits"];
        this.clicks = res["clicks"];
        this.yesterdayClicks = res["yesterdayClicks"];
        this.yesterdayVisits = res["yesterdayVisits"];
      });
    };
  }

}
