import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {Md5} from 'ts-md5/dist/md5';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private hash_check = "8ac9112033b98e2ac6acdf48a9c5b92f"

  constructor( private cookieService: CookieService ) {
    if (!this.cookieService.check("admin")) this.cookieService.set( 'admin', "");
  }

  setAdmin(password: string){
    if ( Md5.hashStr(password) === this.hash_check ) {
      const secureFlag = true;
      const now = new Date();
      now.setHours(now.getHours() + 720);
      this.cookieService.set( 'admin', password, now, null, null, secureFlag, 'Lax' );
      return true;
    } else return false;
  }

  removeAdmin(){
    const secureFlag = true;
    const now = new Date();
    now.setHours(now.getHours() + 720);
    this.cookieService.set( 'admin', "", now, null, null, secureFlag, 'Lax');
  }

  isAdmin(){
    if (Md5.hashStr(this.cookieService.get('admin')) === this.hash_check ) return true;
    else return false;
  }

  
}
