import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { VideosComponent } from './videos/videos.component';
import { PhotosComponent } from './photos/photos.component';
import { SongsComponent } from './songs/songs.component';
import { DatesComponent } from './dates/dates.component';
import { WhoIsAbramComponent } from './who-is-abram/who-is-abram.component';
import { AdminComponent } from './admin/admin.component';

const routes: Routes = [
  {path: '' , component: HomeComponent},
  {path: 'domov' , component: HomeComponent},
  {path: 'abram' , component: WhoIsAbramComponent},
  {path: 'foto' , component: PhotosComponent},
  {path: 'kontakt' , component: ContactComponent},
  {path: 'tvorba' , component: SongsComponent},
  {path: 'terminy' , component: DatesComponent},
  {path: 'videa' , component: VideosComponent},
  {path: 'admin' , component: AdminComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
