import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { CookieService } from 'ngx-cookie-service';
import { RestCallService } from '../rest-call.service';


@Component({
  selector: 'app-who-is-abram',
  templateUrl: './who-is-abram.component.html',
  styleUrls: ['./who-is-abram.component.scss']
})
export class WhoIsAbramComponent implements OnInit {
  constructor(private http: HttpClient, private cookieService: CookieService) { }
  rest= new RestCallService(this.http, this.cookieService);
  auth = new AuthService(this.cookieService)

  ngOnInit() {
    if (!this.auth.isAdmin()){
      this.rest.addClick().subscribe((res : any[])=> {
        console.log(res)
      });
    }
  }

}
