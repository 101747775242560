import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http'
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestCallService {
  headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient,private cookieService: CookieService) {
    this.headers = this.headers.append('Cache-Control', 'no-cache');
    this.headers = this.headers.append('Pragma', 'no-cache');
    this.headers = this.headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
   }

  baseUrl="https://abram.sk/rest/public/"
  authParameter="/"+this.cookieService.get("admin");

  callGet(route:string){   
    let response = this.http.get(this.baseUrl+route, {headers: this.headers, observe: 'response' })
    return response;
  }

  callPost(route:string,data:string){
    return this.http.post(this.baseUrl+route, data ,{observe: 'response' })
  }


  getPhotos(){
    return this.http.get(this.baseUrl+"photos/get", {headers: this.headers})
  }
  getSongs(){
    return this.http.get(this.baseUrl+"songs/get", {headers: this.headers})
  }

  getDates(){
    return this.http.get(this.baseUrl+"dates/get", {headers: this.headers})
  }

  getDatess(): Observable<any>{
    return this.http.get(this.baseUrl+"dates/get", {headers: this.headers})
  }

  postDate(data:any){
    return this.http.post(this.baseUrl+"dates/post"+this.authParameter,data, {headers: this.headers})
  }
  removeDate(id:any){
    return this.http.get(this.baseUrl+"dates/delete/"+id+this.authParameter, {headers: this.headers})
  }t
  addVideo(type:any, videoid:any){
    return this.http.post(this.baseUrl+"videos/post/"+type+"/"+videoid+this.authParameter, {headers: this.headers})
  }
  getVideos(){
    return this.http.get(this.baseUrl+"videos/get", {headers: this.headers})
  }
  removeVideo(id:any){
    return this.http.get(this.baseUrl+"videos/delete/"+id+this.authParameter, {headers: this.headers})
  }
  addClick(){
    return this.http.post(this.baseUrl+"stats/addClick", {headers: this.headers})
  }
  addVisit(){
    return this.http.post(this.baseUrl+"stats/addVisit", {headers: this.headers})
  }
  getStats(){
    return this.http.get(this.baseUrl+"stats/get", {headers: this.headers})
  }
  
}
