import { Component, OnInit } from '@angular/core';
import { RestCallService } from '../rest-call.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../auth.service';



@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {

  constructor(private http: HttpClient, private cookieService: CookieService) { }
  rest= new RestCallService(this.http, this.cookieService);
  auth = new AuthService(this.cookieService)
  images= [];


  ngOnInit() {
      this.rest.getPhotos().subscribe((res : any[])=>{
        this.images = res["photos"];
      });

      if (!this.auth.isAdmin()){
        this.rest.addClick().subscribe((res : any[])=> {
          console.log(res)
        });
      }
  }

}
