import { Component, OnInit } from '@angular/core';
import { RestCallService } from '../rest-call.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../auth.service';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {

  rest = new RestCallService(this.http, this.cookieService);

  videoclips = [];
  actions = [];
  newLink = "";

  constructor(private http: HttpClient,public sanitizer: DomSanitizer,private cookieService: CookieService) { }
  auth = new AuthService(this.cookieService);
  ngOnInit() {
    this.loadVideos();
    if (!this.auth.isAdmin()){
      this.rest.addClick().subscribe((res : any[])=> {
        console.log(res)
      });
    }
    
  }

  openTab(zAkcii: boolean){
    if (zAkcii){
      document.getElementById("zAkcii").classList.add("active-tab")
      document.getElementById("videoklipy").classList.remove("active-tab")

    }
    else {
      document.getElementById("zAkcii").classList.remove("active-tab")
      document.getElementById("videoklipy").classList.add("active-tab")
    }
  }

  loadVideos(){
    this.videoclips = [];
    this.actions=[];
    this.rest.getVideos().subscribe((res : any[])=>{
      this.videoclips = res["videoclips"];
      this.actions = res["actions"];
      console.log(res);
    });
  }

  addVideo(type){
    if (this.newLink != ""){
      this.rest.addVideo(type,this.newLink).subscribe((res : any[])=>{
        console.log(res);
        this.loadVideos();
      });
    }
  }

  deleteVideo(id){
    this.rest.removeVideo(id).subscribe((res : any[])=>{
      console.log(res);
      this.loadVideos();
    });
  }

}
