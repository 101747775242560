import { Component, OnInit } from '@angular/core';
import { RestCallService } from '../rest-call.service';
import { HttpClient } from '@angular/common/http';
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../auth.service';
import { CookieService } from 'ngx-cookie-service';



@Component({
  selector: 'app-dates',
  templateUrl: './dates.component.html',
  styleUrls: ['./dates.component.scss']
})
export class DatesComponent implements OnInit {
  constructor(private http: HttpClient, private cookieService: CookieService) {
   }

  dates= []
  selectedDate = "";
  selectedType = "";
  selectedPlace = "";

  rest= new RestCallService(this.http, this.cookieService);
  auth = new AuthService(this.cookieService);
  rawDates;

  ngOnInit() {
    this.loadDates();
    if (!this.auth.isAdmin()){
      this.rest.addClick().subscribe((res : any[])=> {
        console.log(res)
      });
    }
  }

  sortByDate(date1, date2) {
    // This is a comparison function that will result in dates being sorted in
    // DESCENDING order.
    if (date1["date"] > date2["date"]) return -1;
    if (date1["date"] < date2["date"]) return 1;
    return 0;
  };
  

  loadDates(){
    this.dates = []
    this.rest.getDates().subscribe((res)=>{
      for (let index = 0; index < res["dates"].length; index++) {
        const date = res["dates"][index];
        this.dates.push({"location" : date["location"], "type":date["type"], "date": new Date(date["date"]), "stringDate": this.DateToText(date["date"]), "id": date["id"]});
      }
      this.dates = this.dates.sort(this.sortByDate).reverse();
    });
  }

  DateToText(date:any){
    let dates = date.split("-");
    let year = dates[0];
    let month = dates[1];
    let day = dates[2];
    let stringMonth = "";
    switch(month) {
      case "1": { 
        stringMonth= "jan";
        break; 
      } 
      case "2": { 
        stringMonth= "feb";
        break;  
      }
      case "3": { 
        stringMonth= "mar";
        break;  
      }
      case "4": { 
        stringMonth= "apr";
        break; 
      } 
      case "5": { 
        stringMonth= "máj";
        break; 
      } 
      case "6": { 
        stringMonth= "jún" ;
        break; 
      }
      case "7": { 
        stringMonth= "júl";
        break;  
      }
      case "8": { 
        stringMonth= "aug";
        break;  
      }
      case "9": { 
        stringMonth= "sep";
        break; 
      }
      case "10": { 
        stringMonth= "okt";
        break;  
      }
      case "11": { 
        stringMonth= "nov";
        break; 
      }
      case "12": { 
        stringMonth= "dec";
        break;  
      }
      default: { 
        stringMonth= "";
         break;  
      } 
   }
   return day+"."+stringMonth+" "+year;
  }

  addDate(){
    let dateString = String(this.selectedDate["year"])+"-"+String(this.selectedDate["month"])+"-"+String(this.selectedDate["day"]); 
    let stringToSend = '{"date":"'+dateString+'","type":"'+this.selectedType+'","place":"'+this.selectedPlace+'"}';
    this.rest.postDate(stringToSend).subscribe((res)=>{
      console.log(res)
      this.selectedDate="";
      this.selectedPlace="";
      this.selectedType="";
      this.loadDates();
    });
  }

  deleteDate(id:any){
    this.rest.removeDate(id).subscribe((res)=>{
      console.log(res)
      this.loadDates();
    });
  }

}
