import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../auth.service';
import { RestCallService } from '../rest-call.service';

@Component({
  selector: 'app-content',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  auth = new AuthService(this.cookieService);
  rest= new RestCallService(this.http, this.cookieService);
  constructor(private http: HttpClient, private cookieService: CookieService) {}

  ngOnInit() {
    if (!this.auth.isAdmin()){
      this.rest.addClick().subscribe((res : any[])=> {
        console.log(res)
      });
    }
  }

}
